const lang = {
    page_name: 'One',
    page_title: 'The Age of AI Sovereignty <div>Begins <span class="one-page1-text">Here</span></div>',
    claim_launch_discount: 'Claim $100 Launch Discount',
    optimized_local_ai: 'Optimized for Local AI. Ready for Anything.',
    claim_discount: {
        text_1: 'Take our quick survey to claim a $100 early-bird discount.',
        text_2: 'Pre-orders for the early access version open on Kickstarter in Q2 2025.',
    },
    engine_innovation: 'Driven by Innovation. <span class="engine_text_wrapper">Powered by RTX</span> 5080 Mobile',
    smart_ai_flow: 'Turn complex AI into composable possibilities. Each application is a LEGO piece in your AI playground. Mix, match, and create powerful workflows as unique as your imagination.',
    personal_ai_assistant: 'Personal AI Assistant',
    private_knowledge_base: 'Private Knowledge Base',
    creative_studio: 'Creative Studio',
    task_automation: 'Task Automation',
    computational_power: 'RTX4090-level AI performance at half the cost',
    generation_flux: 'Image generation with Flux.1 compared to Apple M4 Pro*',
    performance_configurations: '*Performance may vary depending on configurations.',
    open_source_builders: 'An Open-Source OS Built for Builders',
    securely_customize: 'Securely access your OS from anywhere, customize it with powerful tools, and stay creative on the go.',
    learn_more: 'Learn More',
    handpicked_apps: 'Handpicked Apps',
    collection_workflow: 'A collection of AI apps and tools ready to supercharge your workflow.',
    smart_info_hub: 'Smart Info Hub',
    one_secure_synced: 'One secure space for all your data, synced everywhere you need it.',
    enterprise_grade_security: 'Enterprise-Grade Security',
    Keep_data_safe: 'Keep your data safe with built-in tools like Tailscale and Cloudflare Tunnel.',
    developer_friendly: 'Developer Friendly',
    permissionless_ecosystem: 'Build, test, and deploy your app using developer tools in a permissionless ecosystem.',
    why_olares_one: 'Why Olares One',
    effortless_sovereignty: 'Effortless Sovereignty',
    power_up: 'Power up and start using it. Everything’s set up and optimized for you.',
    built_for_performance: 'Built for Performance',
    engineered_workloads: 'Engineered for AI tasks, gaming, and heavy workloads.',
    enhanced_local_ai: 'Enhanced Local AI',
    supercharging_workflows: 'Run advanced AI models securely and reliably, supercharging your favorite creative apps and workflows.',
    scalable_for_use: 'Scalable for Use',
    add_more_resources: 'Add more storage or connect other devices whenever you need more resources.',
    works_for_everyone: 'Works for Everyone',
    workspaces_businesses: 'From smart homes to self-hosted workspaces, from individuals to small businesses, adaptable to any need.',
    got_your_back: 'We’ve Got Your Back',
    priority_technical: 'Priority technical support exclusive to Olares One owners.',
    faqs: 'FAQs',
    local_ai_computing: 'How does Olares One support local AI computing?',
    combines_devices: 'Olares One transforms your home into a private AI hub that’s perfect for individuals and small teams alike. With the RTX 5080 Mobile GPU, it creates a seamless AI workspace right in your home. While Olares One runs as your AI server, you can access its capabilities from your regular work computer without switching devices. All data will be safely kept on your own device, within your local network.',
    why_5080: 'Why did you choose the NVDIA RTX 5080 Mobile GPU?',
    stratigically_investment: 'Our choice of the GPU stratigically balances performance and long-term value. It’s an investment upfront for you and your team so you don’t need to worry about the ongoing SaaS subscription costs that typically range from $200-400 monthly. With 16GB of VRAM and advanced Tensor Cores, it provide the computational muscle needed for intensive AI workloads while maintaining efficient operation for continuous home use.',
    why_use: 'Why should I use Olares with Olares One?',
    sovereign_applications: 'Olares is an open-source sovereign cloud operating system designed to be user-friendly and intuitive. You can install a wide range of applications from the app store within a few clicks. With Olares comes pre-installed, Olares One saves you time in setup, provides an out-of-box experience, and delivers peak performance with carefully selected and tested hardware. Featuring an RTX 5080 16GB Mobile GPU, Olares One excels at everything from edge AI computing and content creation to running a personal media server and hosting decentralized applications.',
    how_ai_privacy: 'How does Olares One protect my AI privacy?',
    services_property: 'We understand that your interactions with AI services are valuable intellectual property, which is why we’ve implemented comprehensive security measures that go beyond basic encryption. Your data remains completely under your control, with every operation run entirely air-gapped for sensitive work. We’ve integrated enterprise-grade security features like advanced access controls, MFA, application sandboxing, all while keeping the system user-friendly.',
    purchase_cost: 'When will Olares One be available for purchase? How much will Olares One cost?',
    launch_pricing: 'Olares One is expected to launch in Q2 2025. Pricing will be announced closer to launch. You could share your thoughts through our brief survey to secure an exclusive $100 launch discount. To have a direct interaction with our development team, join our Discord community.',
    what_access: 'What is the early access version?',
    experience_functional: 'The early access version allows you to be among the very first to experience our product. While the hardware is fully functional, we’ll continuously improve the software based on user feedback. As an early adopter, you’ll play a key role in helping us refine the experience while enjoying an exclusive discount.',

    specifications: 'Specifications',
    Hardware_Parameters_list: {
        cpu: 'Intel Core Ultra 5 235HX',
        gpu_vram: 'RTX 5080 M 16GB',
        memory: '128GB DDR5',
        storage: '2TB M.2 PCIe 4.0'
    },
    gpu_vram: 'GPU + VRAM',
    memory: 'Memory',
    storage: 'Storage',
    cpu: 'CPU',
    democratizing_ai_together: 'Democratizing AI Together',
    democratizing_ai_desc: 'Behind Olares is a team of developers who believe AI should be open, local, and accessible.<div>We’re at the beginning of our journey to democratize AI, and we’re building Olares to be the platform we wished existed, the one that respects your privacy, safeguards your intellectual property, and puts you in control.</div>',
    star_git_hub: 'Star Us on GitHub'
}

export default lang;