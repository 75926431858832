export const MESSAGE_TIMEOUT = 2000;

export const MESSAGE_STYLE = {
   warn: {
      icon: "icon-warn-fill",
      color: "#faad14",
      backgroundColor: "#fff7e6",
      borderColor: "#ffe7ba",
   },
   error: {
      icon: "icon-error-fill",
      color: "#ff4d4f",
      backgroundColor: "#fff1f0",
      borderColor: "#ffccc7",
   },
   success: {
      icon: "icon-success-fill",
      color: "#52c41a",
      backgroundColor: "#f6ffed",
      borderColor: "#d9f7be",
   },
   info: {
      icon: "icon-info-fill",
      color: "#1677ff",
      backgroundColor: "#e6f7ff",
      borderColor: "#bae7ff",
   },
};

export const POST_LOCATION = ["All locations", "Beijing, China"]

export const RouteName ={
   ZERO: 'Zero',
   ONE: 'ONE',
   HOME: 'Home',
   DOWNLOAD: 'Download',
   DEMO: 'Demo',
}