const lang = {
    nav_name: 'Demo',
    experience_live  :'Experience Olares Live',
    test_demo: 'Test AI apps, self-hosted tools, and creative solution in a live demo.',
    what_do: 'What can I do?',
    explore_ui: 'Explore the UI',
    familiarize_navigation: 'Familiarize yourself with Olares’s interface and navigation.',
    generate_ai: 'Generate AI art',
    user_img: 'Use pre-installed AI apps and  models to create images.',
    craft_content: 'Craft content',
    generate_ai_models: 'Generate creative content using AI text models.',
    manage_files: 'Manage files',
    upload_files: 'Upload, move, browse, or organize demo files.',
    explore_apps: 'Explore apps',
    Market_meets: 'Browse Olares Market and find the one that meets your needs.',
    get_touch: 'Get in Touch',
    star_project: `Star the <div class="demo-link-wrapper">Olares project</div>`,
    share_feedback: `Share your <div class="demo-link-wrapper">feedback</div>`,
    join_discord: `Join our <div class="demo-link-wrapper">discord</div>`,
    join_discord_zh: `Join our <div class="demo-link-wrapper">WeChat group</div>`,
    data_security: 'Data Security Statement',
    privacy_security: 'At Olares, your privacy and data security are our top priorities. Please note the following about our demo environment:',
    shared_instance: 'Shared Instance: The demo is a shared system. Multiple users can access it simultaneously.',
    no_data_retention: 'No Data Retention: All uploaded files and changes are cleared daily at { hour } (UTC{ utc }).',
    use_responsibly: 'Use Responsibly: Avoid uploading or sharing sensitive info while using the demo apps.',
    timer_dsc: 'Try after { time } minutes',
    try_now: 'Try Olares Now',
    important_notes: 'Important Notes',
    important_notes_dsc: 'This is a shared demo instance open to all visitors. Do not upload or expose any personal information or sensitive data while exploring. ',
    credential: 'Credential',
    next: 'Next',
    open_demo: 'Open Olares Demo',
    waiting_start: 'Preparing the demo...',
    open_web_ui_account: 'Open WebUI Account',
    login_pwd: 'Olares Login Password',
    cpu: 'CPU',
    ram: 'RAM',
    vram: 'VRAM',
    username: 'Username',
    password: 'Password',
    cancelled: 'Cancelled',
    fetching: 'Fetching',
    queuing: 'Queuing',
    pending: 'Pending',
    installing: 'Installing',
    restoring: 'Restoring',
    restore_error: 'Restore error',
    restarting: 'Restarting',
    stopping: 'Stopping',
    starting: 'Starting',
    running: 'Running',
    offline: 'Offline',
    stopped: 'Stopped',
    errored: 'Errored',
    destroy_to_be_confirmed: 'Destruction pending confirmation',
    waiting_destroy: 'Awaiting destruction',
    destroying: 'Destroying',
    destroyed: 'Destroyed',
    canceling: 'Canceling',
    canceled: 'Canceled',
    inactivated: 'Inactivated',
    resetting: 'resetting'
}

export default lang;