<template>
  <router-view></router-view>
</template>
<script setup>
import { inject } from "@vercel/analytics";
import { injectSpeedInsights } from "@vercel/speed-insights";
import { initLang } from "./common/language.js";
import { useI18n } from "vue-i18n";
import { watch } from "vue";
import { lang_zh } from "./utils/lang";

const { t, locale } = useI18n();

initLang();

if (!lang_zh.value) {
  inject();
  injectSpeedInsights();
}

watch(
  () => locale.value,
  () => {
    document.title = t("website_title");
  },
  {
    immediate: true,
  }
);
</script>
<style>
body {
  font-family: Inter;
}
#app {
  overflow-x: hidden;
}
</style>
