
function saveGclidToLocalStorage() {
    const urlParams = new URLSearchParams(window.location.search);
    const gclid = urlParams.get('gclid');

    if (gclid) {
      try {
        localStorage.setItem('gclid', gclid);
      } catch (e) {
        console.error(e);
      }
    }
  }
  
  saveGclidToLocalStorage();