<template>
  <div :class="isMenu ? 'menu_box height' : 'fade-in-top'">
    <div class="menu_top">
      <div class="menu_backgr menu_logo">
        <img class="logo_1" src="../../assets/v2/logo.svg" alt="logo">
        <img class="logo_2" src="../../assets/v2/logo-text-black.svg" alt="">
      </div>
      <div @click="closeMebu" class="menu_backgr close_menu"></div>
    </div>
    <ul class="menu-nav-container">
      <li :data-to="nav.id" @click="goMenu(nav.id, nav)" class="menu_nav" v-for="nav in NavList" :key="nav.id"
        :style="nav.name === 'Contact' ? '' : 'border-bottom: 1px solid #EFEFEF;'">{{ nav.name }}</li>
        <li 
        @click="langChange"
        class="menu_nav" 
        style="'border-bottom: 1px solid #EFEFEF;'">
        <span v-for="(item, index) in langs" :key="item.value">{{index != 0 ? '/' : ''}}{{item.name}}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
// import { useRouter } from "vue-router";
const langs = [
  { label: "English", value: "en", name: "En" },
  { label: "简体中文", value: "zh", name: "中文" },
];
export default {
  name: 'drop_down_menu',
  components: {
  },
  props: ['NavList'],
  emits: ['goMenu'],
  setup(props,cxt) {
    const { locale } = useI18n();
    // const router = useRouter();
    const isMenu = ref(false);
    const handelClick = () => {
      isMenu.value = true;
    };
    const closeMebu = () => {
      isMenu.value = false;
    }
    const goMenu = (id, item) => {
      cxt.emit('goMenu', id, item)
      isMenu.value = false;
    }
    const langChange = () => {
      if(locale.value === 'en') {
        locale.value = 'zh';
      }else {
        locale.value = 'en';
      }
      localStorage.setItem("lang", locale.value);
      isMenu.value = false;
    };
    return {
      isMenu,
      goMenu,
      closeMebu,
      handelClick,
      langs,
      langChange
    }
  },

}
</script>

<style scoped>
ul {
  margin: 0;
}

.menu_box {
  width: 100%;
  background: #FFFFFF;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  transition: all 0.3s ease;
  overflow: hidden;
}

.fade-in-top {
  width: 100%;
  transition: all 0.3s ease;
  height: 0px !important;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background: #FFFFFF;
}

.close_menu {
  font-size: 30px;
}

.menu_backgr {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.menu_top {
  width: 100%;
  height: 72px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 1px solid #EFEFEF;

}

.menu_logo {
  width: 127px;
  /* background-image: url('../../assets/logo.svg'); */
  margin-left: 20px;
}

.menu_logo .logo_1 {
  width: 36px;
  height: 34px;
  width: 20px;
  margin-right: 10px;
}

.menu_logo .logo_2 {
  width: 58px;
}

.close_menu {
  width: 24px;
  height: 24px;
  background-image: url('../../assets/colse_muen.png');
  margin-left: auto;
  margin-right: 12px;
}
.menu-nav-container {
  max-height: calc(100vh - 72px);
  overflow-y: auto;
}
.menu_nav {
  width: 100%;
  height: 72px;
  font-size: 16px;
  font-weight: 500;
  color: #151515;
  line-height: 72px;
  padding-left: 20px;
}
</style>