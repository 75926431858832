const lang = {
    nav_name: 'Demo',
    experience_live  :'立即体验 Olares',
    test_demo: '探索 AI 应用、自托管工具和创意解决方案。',
    what_do: '我可以做什么？',
    explore_ui: '探索用户界面',
    familiarize_navigation: '熟悉 Olares 的界面和导航',
    generate_ai: '生成 AI 艺术作品',
    user_img: '使用预装 AI 应用和模型生成图像',
    craft_content: '创作内容',
    generate_ai_models: '借助本地大语言模型生成创意内容',
    manage_files: '管理文件',
    upload_files: '上传、移动、浏览或整理演示文件',
    explore_apps: '探索应用',
    Market_meets: '浏览 Olares 应用市场，寻找符合你需求的应用',
    get_touch: '反馈与交流',
    star_project: `收藏 <div class="demo-link-wrapper">Olares 项目</div>`,
    share_feedback: `分享 <div class="demo-link-wrapper">使用体验</div>`,
    join_discord: `加入<div class="demo-link-wrapper">Discord</div>`,
    join_discord_zh: `加入<div class="demo-link-wrapper">微信群</div>`,
    data_security: '数据隐私与安全',
    privacy_security: 'Olares 始终将隐私与数据安全放在首位。请阅读并知悉以下关于此演示环境的重要信息：',
    shared_instance: '共享实例：Olares 支持多用户同时访问。',
    no_data_retention: '无数据保留：每日 { hour }（UTC{ utc }）会自动清除所有上传数据和更改内容。',
    use_responsibly: '安全访问：请勿在演示环境中上传或分享敏感信息。',
    timer_dsc: '{ time } 分钟后再试',
    try_now: '立即体验 Olares',
    important_notes: '重要提示',
    important_notes_dsc: '本环境为共享演示实例。在体验时，请勿上传或暴露任何个人信息或敏感数据。',
    credential: '账号和密码',
    next: '下一步',
    open_demo: '进入 Olares',
    waiting_start: 'Demo 准备中...',
    open_web_ui_account: 'Open WebUI 账户',
    login_pwd: 'Olares 登陆密码',
    cpu: 'CPU',
    ram: '内存',
    vram: '显存',
    username: '用户名',
    password: '密码',
    cancelled: '已取消',
    fetching: '获取中',
    queuing: '排队中',
    pending: '待处理',
    installing: '安装中',
    restoring: '恢复中',
    restore_error: '恢复出错',
    restarting: '重启中',
    stopping: '正在停止',
    starting: '启动中',
    running: '运行中',
    offline: '离线',
    stopped: '已停止',
    errored: '出错',
    destroy_to_be_confirmed: '销毁待确认',
    waiting_destroy: '等待销毁',
    destroying: '正在销毁',
    destroyed: '已销毁',
    canceling: '正在取消',
    canceled: '已取消',
    inactivated: '未激活',
    resetting: '重置中'

}

export default lang;