<template>
  <div class="bottom-content">
    <div class="footer-item-container">
      <div class="footer-item-layout">
        <div class="footer-item-title">{{ $t("product") }}</div>
        <div class="opacity-text footer-item-text">Olares</div>
        <div class="opacity-text footer-item-text">Olares Space</div>
        <div class="opacity-text footer-item-text">LarePass</div>
      </div>
      <div class="footer-item-layout">
        <div class="footer-item-title" v-if="!lang_zh">
          {{ $t("protocol") }}
        </div>
        <a
          class="opacity-text footer-item-text"
          :href="link.footer_snowinning"
          target="_blank"
          >Snowinning</a
        >
      </div>
      <div class="footer-item-layout">
        <div class="footer-item-title">{{ $t("resource") }}</div>
        <a
          class="opacity-text footer-item-text"
          :href="link.footer_docs"
          target="_blank"
          >{{ $t("docs") }}</a
        >
      </div>
      <!-- <div class="footer-item-layout footer-item-layout2">
            <div class="footer-item-title">Company</div>
            <div class="opacity-text footer-item-text2">
              <div class="item-child">
                <img src="@/assets/v2/local.svg" alt="" />
                <div class="item-text">
                  8502 Preston Rd. Inglewood, Maine 98380, USA
                </div>
              </div>
            </div>
          </div> -->
    </div>
  </div>
  <div class="bottom">
    <div class="logo-container">
      <span class="logo-name">Olares</span>
    </div>
    <div class="opacity-text left-text">
      {{ $t("footer_dsc") }}
    </div>
    <div class="link-container">
      <a :href="link.footer_github" target="_blank" rel="noopener noreferrer">
        <img src="../../assets/v2/github-circle.svg" alt="" />
      </a>
      <a
        :href="link.footer_discord"
        target="_blank"
        rel="noopener noreferrer"
        v-if="!lang_zh"
      >
        <img class="link-2" src="../../assets/v2/temp-circle.svg" alt="" />
      </a>
      <a
        :href="link.footer_twitter"
        target="_blank"
        rel="noopener noreferrer"
        v-if="!lang_zh"
      >
        <img src="../../assets/v2/twiter-circle.svg" alt="" />
      </a>
      <a :href="link.footer_medium" target="_blank" rel="noopener noreferrer">
        <img src="../../assets/v2/bg-circle.svg" alt="" />
      </a>
      <span class="wechat-popup" @click="mouseenterHandler(5)" v-if="wechatShowWithMeta">
        <img src="../../assets/v2/wechat-circle.svg" alt="" />
        <div class="qr-container">
          <div class="qr-wrapper">
            <img class="qr-img" src="../../assets/v2/wechatQR.jpeg" alt="" />
          </div>
        </div>
      </span>
    </div>
    <div class="opacity-text info" v-if="!lang_zh">@2024 BEC LAB</div>
    <div class="icp-container-mobile" v-if="lang_zh">
      <a
        class="icp-text-mobile"
        href="https://beian.miit.gov.cn/"
        target="_blank"
        >京ICP备2024083577号</a
      >
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { lang_zh } from "@/utils/lang";
import link from "@/common/link";
import { useRoute } from "vue-router";

const route = useRoute();

const wechatShowWithMeta = computed(() => !route.meta.wechat_hidden)
const active = ref(-1);

const mouseenterHandler = (index) => {
  active.value = index;
};
</script>

<style scoped>
.bottom-content {
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 28px;
}
.bottom {
  padding-left: 8px;
}
.logo-container {
  display: flex;
  align-items: center;
}
.log-img {
  height: 23px;
}
.logo-name {
  color: #fff;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.188px;

  margin-top: 40px;
}
.opacity-text {
  color: var(--www-risecalendar-com-nero, #fff);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.3px;
  opacity: 0.5;
}
.left-text {
  margin-top: 8px;
}
.link-container {
  margin-top: 24px;
  display: flex;
  gap: 20px;
}
.link-container img {
  width: 32px;
  height: 32px;
}
.link-2 {
}
.info {
  margin-top: 16px;
}
.footer-item-container {
}
.footer-item-layout {
  display: flex;
  flex-direction: column;
}
.footer-item-layout2 {
}
.footer-item-title {
  color: var(--www-risecalendar-com-nero, #fff);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.3px;
  padding: 8px;
}
.footer-item-text {
  padding: 8px;
}
.footer-item-text2 {
  padding: 8px;
}
.item-child {
  display: flex;
}
.item-child img {
  margin-right: 12px;
}
.item-text {
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  width: 238px;
}
.github-button-container {
  display: inline-flex;
  padding: 6px 20px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  background: #fff;
}
.github-button-container:hover {
  color: #fff;
  background: linear-gradient(90deg, #ff7b01 0%, #ff4e42 46.71%, #8dbbff 100%);
}
.github-button-container:hover .github-icon path {
  fill: white;
}
.github-button {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.wechat-popup {
  position: relative;
}
.wechat-popup:hover .qr-container {
  display: block;
}
.qr-container {
  display: none;
  padding-bottom: 8px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -100%);
}
.qr-wrapper {
  padding: 12px;
  border-radius: 12px;
  background: #fff;
}
img.qr-img {
  width: 96px;
  height: 96px;
}

.icp-container-mobile {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.3px;
  opacity: 0.5;
  margin-top: 16px;
}
.icp-text-mobile {
  color: #fff;
}
</style>
