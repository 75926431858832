const lang = {
    page_name: 'One',
    page_title: '定义你的 <span class="one-page1-text">AI</span> 新时代',
    claim_launch_discount: '领取 $100 专属折扣',
    optimized_local_ai: '赋能每一次创造。',
    claim_discount: {
        text_1: '完成我们的问卷，即可锁定价值 $100 的早鸟优惠。',
        text_2: '抢鲜体验版将于 2025 年第二季度在 Kickstarter 开启预售。',
    },
    engine_innovation: '搭载 RTX 5080 移动版<div>释放无尽创意</div>',
    smart_ai_flow: '将 AI 技术化繁为简。像拼搭乐高一样，轻松组合和运行复杂的 AI 应用，打造你的专属工作流。',
    personal_ai_assistant: '打造专属 AI 助手',
    private_knowledge_base: '将私域信息转化为知识库',
    creative_studio: '助力创意快速落地',
    task_automation: '自动化碎片任务',
    computational_power: '媲美 RTX 4090 的性能，同等旗舰 PC 一半的价格',
    generation_flux: '相较于 Apple M4 Pro，使用 Flux.1 模型生图更高效*',
    performance_configurations: '*性能因使用场景和配置而异。',
    open_source_builders: '开源私有云系统<div>重构数字主场</div>',
    securely_customize: '无论何时何地，安全访问操作系统，随心定制专属工具，保持灵感与效率同步。',
    learn_more: '了解更多',
    handpicked_apps: '精选应用',
    collection_workflow: '精心挑选的 AI 应用与工具，让工作流更胜一筹。',
    smart_info_hub: '智能信息中心',
    one_secure_synced: '所有数据安全存储，一处访问，随时同步。',
    enterprise_grade_security: '企业级安全',
    Keep_data_safe: '内置 Tailscale 和 Cloudflare Tunnel 等工具，保障数据安全。',
    developer_friendly: '开发者友好',
    permissionless_ecosystem: '在无需授权的开放生态中，轻松构建、测试和部署应用。',
    why_olares_one: '为什么选择 Olares One',
    effortless_sovereignty: '开箱即用',
    power_up: '无需复杂设置，开机即可投入工作。',
    built_for_performance: '卓越性能',
    engineered_workloads: '专为 AI 任务、设计工作流和高负载应用而生。',
    enhanced_local_ai: '本地 AI',
    supercharging_workflows: '在家安全运行先进 AI 模型，为你的创意应用注入强劲动力。',
    scalable_for_use: '灵活扩展',
    add_more_resources: '支持扩展存储和添加节点，满足你的增量需求。',
    works_for_everyone: '适配多场景',
    workspaces_businesses: '从智能家居到自托管工作站，从个人用户到小型团队，全面适配。',
    got_your_back: '专属支持',
    priority_technical: '享受 Olares One 用户专属的 VIP 技术支持服务。',
    faqs: '常见问题',
    local_ai_computing: 'Olares One 如何支持本地 AI ？',
    combines_devices: 'Olares One 是一台专为本地 AI 计算优化的设备，将 AI 算力带到你的身边。它的核心是 RTX 5080 移动版显卡，结合 128GB 内存和 2TB 固态硬盘，轻松应对 AI 模型训练、实时生成和高负载任务。<br>通过本地运行 AI 服务，你的数据始终保存在设备中，不依赖云服务，安全性和隐私性都得到了最大程度的保障。',
    why_5080: '为什么选择 NVIDIA RTX 5080 移动版 GPU？',
    stratigically_investment: 'RTX 5080 移动版在性能、能效和长期价值之间实现了完美平衡。相比每月高达 ¥1,500-3,000 的 SaaS 订阅服务，RTX 5080 是一次性投资，长期使用更具性价比。有 16GB 显存和 Tensor Core 技术加持，它为高强度 AI 任务提供澎湃动力，同时满足日常高效运行需求。',
    why_use: '为什么我应该在 Olares One 上使用 Olares？',
    sovereign_applications: 'Olares 系统是为用户打造的最佳搭档，Olares One 则让一切更进一步。<br>自行搭建系统可能需要面临硬件选择、驱动配置、兼容性调试等繁琐过程，而 Olares One 确保你开箱即用。<br>搭载英伟达 RTX 5080 16GB 移动版显卡，Olares One 为高强度 AI 任务和复杂工作流提供全面支持，让你的创意与效率无限延展。',
    how_ai_privacy: 'Olares One 如何保护我的 AI 隐私？',
    services_property: '你的数据隐私是我们的核心关注点。Olares One 采用全方位安全架构，所有操作均在本地完成，敏感任务可在隔离环境中运行。内置高级访问控制、多因素认证（MFA）及应用沙盒技术，既确保安全性，又不影响用户体验。',
    purchase_cost: 'Olares One 什么时候上市？价格是多少？',
    launch_pricing: 'Olares One 预计将在 2025 年第二季度正式发布。具体价格将在发布前公布。完成我们的问卷，即可提前锁定价值 $100 的发布优惠。加入我们的社群，与开发者团队互动，了解更多动态。',
    what_access: '什么是抢鲜体验版本？',
    experience_functional: '抢鲜体验版本让你成为第一批体验产品的用户。我们会根据用户反馈持续优化软件。作为早期用户，你不仅能帮助我们一起打磨产品，还能享受专属优惠。',

    specifications: '规格',
    Hardware_Parameters_list: {
        cpu: 'Intel Core Ultra 5 235HX',
        gpu_vram: 'RTX 5080 M 16GB',
        memory: '128GB DDR5',
        storage: '2TB M.2 PCIe 4.0'
    },
    gpu_vram: '显卡',
    memory: '内存',
    storage: '存储',
    cpu: '处理器',
    democratizing_ai_together: '让 AI 更触手可及',
    democratizing_ai_desc: 'Olares 的初心是让技术更贴近每一个人。我们的团队由一群对 AI 和开源技术充满热情的开发者组成，致力于打造一个尊重隐私、灵活开放、以用户为中心的平台。Olares One 是我们迈向未来的第一步，让每个人都能掌控自己的技术和数据，开启无限创造的可能。',
    star_git_hub: '点亮 GitHub 星标'
}

export default lang;