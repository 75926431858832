import { computed } from "vue";
import { useI18n } from "vue-i18n";
import link from "@/common/link";
import { lang_zh } from "@/utils/lang";
import { RouteName } from "./constant";


export const  NavList = computed(() => {
    const { t } = useI18n();

    const list1 = [
      {
        name: t("home"),
        id: RouteName.HOME,
      },
      {
        name: t("docs"),
        id: 1,
        link: link.docs_path
      },
      {
        name: t("market"),
        id: 2,
        link: link.market_path
      },
      {
        name: t("blog"),
        id: 3,
        link: link.blog_path
      },
      {
        name: t("space"),
        id: 4,
        link: link.footer_terminus_space
      },
    ];
    const list2 = [
      {
        name: t("Zero"),
        id: RouteName.ZERO,
      },
    ];

    const list2_2 = [
      {
        name: t("One"),
        id: RouteName.ONE,
      },
    ];
  
    const list3 = [
      {
        name: t("download.termipass"),
        id: RouteName.DOWNLOAD,
      },
      {
        name: t("demo.nav_name"),
        id: RouteName.DEMO,
      },
    ];
  
    return lang_zh.value
      ? list1.concat(list2).concat(list3)
      : list1.concat(list2_2).concat(list3);
  });